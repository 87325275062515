body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-chai-color: #efd7b3;
  --main-song-color: #d1b01d;
  --main-film-color: #65a9f0;
  --main-khel-color: #1eb89a;
  --main-card-color: #f47c04;
  --main-muqabla-color: #9126d4;
  --card-red-color: #ed405c;
  --main-done-card-color: #65f087;
  --main-open-card-color: #ca6fa8;
  --main-answer-card-color: #be1576;
  --old-done-card-color: springgreen;
  --main-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-in-progress-background {
  background-color: #ca6fa8 !important;
  background-color: var(--main-open-card-color) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'FunFont';
  src: url(/static/media/FunFont.8124f797.otf);
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App-body {
  background-color: #ca6fa8;
  background-color: var(--main-open-card-color);
  height: 100vh;
  height: 100vh;
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.outer-game-card {
  background-color: floralwhite;
}

.player-input-list-players {
  margin-top: 10px;
  padding-top: 10px;
  flex: 1 1 auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.player-input-list-players::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.player-input {
  height: 100%;
  margin-top: 50px;
  width: 350px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.player-input-title {
  text-shadow: -6px 6px 0 black, -7px 7px 0 black, -8px 8px 0 black;
}

.player-input-input {
  margin-top: 100px;
  flex: 0 1 auto;
  width: 100%;
}

.player-chip {
  float: left;
}

.basic-card {
  background-color: #f47c04 !important;
  background-color: var(--main-card-color) !important;
  color: floralwhite;
  position: relative;
  width: 400px;
  min-height: 625px;
  margin-top: 30px;
  display: flex;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.basic-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.center-text-div {
  margin: auto;
}

.full-screen-center-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.basic-card-inner-flipped {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.done-card {
  background-color: #65f087 !important;
  background-color: var(--main-done-card-color) !important;
  position: relative;
  width: 400px;
  min-height: 625px;
  margin-top: 30px;
  -webkit-animation: doneCardEnterAnimation 0.25s ease-in-out forwards;
          animation: doneCardEnterAnimation 0.25s ease-in-out forwards;
}

.top-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.hidden-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipped-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.basic-card-enter {
  -webkit-animation: cardEnterAnimation 0.1s ease-in-out forwards;
          animation: cardEnterAnimation 0.1s ease-in-out forwards;
}

.card-bounce-text {
  -webkit-animation: cardBounceAnimation 0.2s 0.25s ease-in-out forwards;
          animation: cardBounceAnimation 0.2s 0.25s ease-in-out forwards;
}

.card-out-in-text {
  -webkit-animation: cardOutInAnimation 0.2s 0.25s ease-in-out forwards;
          animation: cardOutInAnimation 0.2s 0.25s ease-in-out forwards;
}

.card-slide-text {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  -webkit-animation: cardSlideAnimation 0.2s 0.25s ease-in-out forwards;
          animation: cardSlideAnimation 0.2s 0.25s ease-in-out forwards;
}

.card-wave-text span {
  display: inline-block;
  -webkit-animation: cardWaveAnimation 1s ease-in-out;
          animation: cardWaveAnimation 1s ease-in-out;
  -webkit-animation-delay: calc(0.25s + (.1s * var(--i)));
          animation-delay: calc(0.25s + (.1s * var(--i)));
}

.prompt-info {
  color: floralwhite !important;
  display: flex;
  flex-direction: column;
}

.prompt-main-color {
  background-color: #f47c04 !important;
  background-color: var(--main-card-color) !important;
}

.prompt-khel-color {
  background-color: #1eb89a !important;
  background-color: var(--main-khel-color) !important;
}

.prompt-song-color {
  background-color: #d1b01d !important;
  background-color: var(--main-song-color) !important;
}

.prompt-film-color {
  background-color: #65a9f0 !important;
  background-color: var(--main-film-color) !important;
}

.prompt-muqabla-color {
  background-color: #9126d4 !important;
  background-color: var(--main-muqabla-color) !important;
}

.prompt-answer-color {
  background-color: #be1576 !important;
  background-color: var(--main-answer-card-color) !important;
}

.funfont-text {
  font-family: FunFont !important;
}

.card-text {
  text-transform: uppercase;
  font-weight: 500 !important;
}

.card-small-line {
  line-height: 1.4 !important;
}

.card-main-text {
  flex-grow: 1;
}

.cancel-card-dialog {
  background-color: #2f6196 !important;
  font-family: FunFont !important;
  color: floralwhite !important;
}

.cancel-icon-button {
  position: absolute !important;
  top: 5px !important;
  right: 10px !important;
  opacity: 0.6 !important;
  z-index: 100 !important;
}

.light-button {
  color: floralwhite !important;
}

@-webkit-keyframes cardBounceAnimation {
    0%{
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
    }
    40%{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
    70%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    85%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes cardBounceAnimation {
    0%{
        -webkit-transform: scale(0.75);
                transform: scale(0.75);
    }
    40%{
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
    70%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    85%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes cardSlideAnimation {
    0%{
        -webkit-transform: skewX(30deg) translateX(50%);
                transform: skewX(30deg) translateX(50%);
    }
    70% {
      -webkit-transform: skewX(10deg) translateX(-10%);
              transform: skewX(10deg) translateX(-10%);
    }
    90% {
      -webkit-transform: skewX(-5deg) translateX(5%);
              transform: skewX(-5deg) translateX(5%);
    }
    100%{
        -webkit-transform: skewX(0deg) translateX(0%);
                transform: skewX(0deg) translateX(0%);
    }
}

@keyframes cardSlideAnimation {
    0%{
        -webkit-transform: skewX(30deg) translateX(50%);
                transform: skewX(30deg) translateX(50%);
    }
    70% {
      -webkit-transform: skewX(10deg) translateX(-10%);
              transform: skewX(10deg) translateX(-10%);
    }
    90% {
      -webkit-transform: skewX(-5deg) translateX(5%);
              transform: skewX(-5deg) translateX(5%);
    }
    100%{
        -webkit-transform: skewX(0deg) translateX(0%);
                transform: skewX(0deg) translateX(0%);
    }
}

@-webkit-keyframes cardOutInAnimation {
    30% {
      -webkit-transform: translateX(-30px);
              transform: translateX(-30px);
    }
    60% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
    }
    100%{
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
}

@keyframes cardOutInAnimation {
    30% {
      -webkit-transform: translateX(-30px);
              transform: translateX(-30px);
    }
    60% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
    }
    100%{
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
}

@-webkit-keyframes cardWaveAnimation {
  0%,40%,100% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px)
  }
}

@keyframes cardWaveAnimation {
  0%,40%,100% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
  20% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px)
  }
}

@-webkit-keyframes cardExitAnimation {
    100%{
        -webkit-transform: rotate(5deg) translate(50%, 10%);
                transform: rotate(5deg) translate(50%, 10%);
        opacity: 0.1;
    }
}

@keyframes cardExitAnimation {
    100%{
        -webkit-transform: rotate(5deg) translate(50%, 10%);
                transform: rotate(5deg) translate(50%, 10%);
        opacity: 0.1;
    }
}

@-webkit-keyframes flippedCardExitAnimation {
  100%{
    -webkit-transform: rotateX(5deg) rotateY(180deg) translate(50%, 10%);
            transform: rotateX(5deg) rotateY(180deg) translate(50%, 10%);
    opacity: 0.1;
  }
}

@keyframes flippedCardExitAnimation {
  100%{
    -webkit-transform: rotateX(5deg) rotateY(180deg) translate(50%, 10%);
            transform: rotateX(5deg) rotateY(180deg) translate(50%, 10%);
    opacity: 0.1;
  }
}

@-webkit-keyframes cardEnterAnimation {
    0%{
        -webkit-transform: scale(90%);
                transform: scale(90%);
    }
    100%{
        -webkit-transform: scale(100%);
                transform: scale(100%);
    }
}

@keyframes cardEnterAnimation {
    0%{
        -webkit-transform: scale(90%);
                transform: scale(90%);
    }
    100%{
        -webkit-transform: scale(100%);
                transform: scale(100%);
    }
}

@-webkit-keyframes doneCardEnterAnimation {
    0%{
        -webkit-transform: rotate(-5deg) translate(-20%, 10%) scale(.5);
                transform: rotate(-5deg) translate(-20%, 10%) scale(.5);
    }
    50% {
      -webkit-transform: rotate(2deg) translate(2%, -1%) scale(1.05);
              transform: rotate(2deg) translate(2%, -1%) scale(1.05);
    }
    75% {
      -webkit-transform: rotate(-1deg) translate(-1%, 1%) scale(.95);
              transform: rotate(-1deg) translate(-1%, 1%) scale(.95);
    }
    100%{
        -webkit-transform: rotate(0deg) translate(0) scale(1);
                transform: rotate(0deg) translate(0) scale(1);
    }
}

@keyframes doneCardEnterAnimation {
    0%{
        -webkit-transform: rotate(-5deg) translate(-20%, 10%) scale(.5);
                transform: rotate(-5deg) translate(-20%, 10%) scale(.5);
    }
    50% {
      -webkit-transform: rotate(2deg) translate(2%, -1%) scale(1.05);
              transform: rotate(2deg) translate(2%, -1%) scale(1.05);
    }
    75% {
      -webkit-transform: rotate(-1deg) translate(-1%, 1%) scale(.95);
              transform: rotate(-1deg) translate(-1%, 1%) scale(.95);
    }
    100%{
        -webkit-transform: rotate(0deg) translate(0) scale(1);
                transform: rotate(0deg) translate(0) scale(1);
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .App-body {
    background-color: floralwhite;
  }
  
  /* Styles */
  .basic-card {
    background-color: #f47c04 !important;
    background-color: var(--main-card-color) !important;
    width: calc(100% - 10px);
    height: calc(100vh - 10px);
    height: calc(100vh - 10px);
    height: calc(var(--main-height) - 10px);
    margin-top: 0px;
    margin: auto;
  }

  .done-card {
    background-color: springgreen !important;
    width: calc(100% - 10px);
    height: calc(100vh - 10px);
    height: calc(100vh - 10px);
    height: calc(var(--main-height) - 10px);
    margin-top: 0px;
    margin: auto;
  }

  .prompt-main-color {
    background-color: #f47c04 !important;
    background-color: var(--main-card-color) !important;
  }

  .prompt-khel-color {
    background-color: #1eb89a !important;
    background-color: var(--main-khel-color) !important;
  }

  .prompt-song-color {
    background-color: #d1b01d !important;
    background-color: var(--main-song-color) !important;
  }

  .prompt-film-color {
    background-color: #65a9f0 !important;
    background-color: var(--main-film-color) !important;
  }

  .prompt-answer-color {
    background-color: #be1576 !important;
    background-color: var(--main-answer-card-color) !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (orientation: landscape) and (max-device-width: 800px) {
  .App-body {
    background-color: floralwhite;
  }
  
  /* Styles */
  .basic-card {
    background-color: #f47c04 !important;
    background-color: var(--main-card-color) !important;
    width: calc(100% - 10px);
    height: calc(100vh - 10px);
    height: calc(100vh - 10px);
    height: calc(var(--main-height) - 10px);
    margin-top: 0px;
    margin: auto;
  }

  .done-card {
    background-color: springgreen !important;
    width: calc(100% - 10px);
    height: calc(100vh - 10px);
    height: calc(100vh - 10px);
    height: calc(var(--main-height) - 10px);
    margin-top: 0px;
    margin: auto;
  }

  .prompt-main-color {
    background-color: #f47c04 !important;
    background-color: var(--main-card-color) !important;
  }

  .prompt-khel-color {
    background-color: #1eb89a !important;
    background-color: var(--main-khel-color) !important;
  }

  .prompt-song-color {
    background-color: #d1b01d !important;
    background-color: var(--main-song-color) !important;
  }

  .prompt-film-color {
    background-color: #65a9f0 !important;
    background-color: var(--main-film-color) !important;
  }

  .prompt-answer-color {
    background-color: #be1576 !important;
    background-color: var(--main-answer-card-color) !important;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

